.producto {
    overflow: hidden;
    $p: &;

    &__hero {
        //background: url('/images/perros/product/hero.jpg') no-repeat center center / cover;
        width: 100%;
        position: relative;
        padding-top: 70%;
        background-color: $darkestGray !important;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 50%;
          background: linear-gradient(to top, rgba(0,0,0,.7), transparent);
        }

        @include mq(md) {
            padding-top: 200px;
        }

        &--perros-adult {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-adults-mediana.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-adults-mediana.jpg') no-repeat center center / cover;
            }
        }

        &--perros-adult-mp {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-adults-peq.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-adults-peq.jpg') no-repeat center center / cover;
            }
        }

        &--perros-cachorros {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-cachorros-med.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-cachorros-med.jpg') no-repeat center center / cover;
            }
        }

        &--perros-cachorros-mp {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-cachorros-peq.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-cachorros-peq.jpg') no-repeat center center / cover;
            }
        }

        &--perros-senior {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-senior-mediana.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-senior-mediana.jpg') no-repeat center center / cover;
            }
        }

        &--perros-senior-mp {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-senior-peq.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-senior-peq.jpg') no-repeat center center / cover;
            }
        }

        &--perros-criadores {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-criadores.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-criadores.jpg') no-repeat center center / cover;
            }
        }

        &--perros-light {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-light.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-light.jpg') no-repeat center center / cover;
            }
        }

        &--perros-derma {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-derma.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-derma.jpg') no-repeat center center / cover;
            }
        }

        &--perros-ultra-mash-puppies {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-ultra-mash-puppies.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-ultra-mash-puppies.jpg') no-repeat center center / cover;
            }
        }

        &--perros-ultra-osteo {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-ultra-osteo.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-ultra-osteo.jpg') no-repeat center center / cover;
            }
        }

        &--perros-ultra-vita-plus {
            background: url('/images/perros-horizontal-ficha-producto/hero-2-ultra-vita-plus.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/perros-horizontal-ficha-producto/hero-2-ultra-vita-plus.jpg') no-repeat center center / cover;
            }
        }

        &--gatos-adult {
            background: url('/images/gatos/product/hero-2-katze-adult.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/gatos/product/hero-2-katze-adult.jpg') no-repeat center center / cover;
            }
        }

        &--gatos-kitten {
            background: url('/images/gatos/product/hero-2-katze-kitten.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/gatos/product/hero-2-katze-kitten.jpg') no-repeat center center / cover;
            }
        }

        &--gatos-senior {
            background: url('/images/gatos/product/hero-2-katze-senior.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/gatos/product/hero-2-katze-senior.jpg') no-repeat center center / cover;
            }
        }

        &--gatos-light {
            background: url('/images/gatos/product/hero-2-katze-light.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/gatos/product/hero-2-katze-light.jpg') no-repeat center center / cover;
            }
        }

        &--gatos-urinary {
            background: url('/images/gatos/product/hero-2-katze-urinary.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/gatos/product/hero-2-katze-urinary.jpg') no-repeat center center / cover;
            }
        }

        &--gatos-stress {
            background: url('/images/gatos/product/hero-2-katze-stress-control.jpg') no-repeat top center / 170%;

            @include mq(md) {
                background: url('/images/gatos/product/hero-2-katze-stress-control.jpg') no-repeat center center / cover;
            }
        }

        & > img {
            position: absolute;
            right: 20px;
            z-index: 2;
            display: block;
            width: 40%;
            bottom: 30px;

            @include mq(md) {
              top: calc(50% + 100px);
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
              width: 22%;
              right: auto;
              bottom: auto;
            }
        }

        .container,
        .row {
            height: 100%;
        }

        &__container {
            width: 100%;
            background: $white;
            
            @include mq(md) {
                height: 0;
                padding-bottom: 100%;
                position: relative;
            }
        }

        &__content {
            padding: 20px;
            padding-bottom: 50%;

            @include mq(md) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 70%;
                padding-right: 80px;
                padding-bottom: 20px;

            }
        }

        &__subtitle {
            color: $darkestGray;
            margin: 0;
            margin-bottom: 10px;
            font-size: 14px;

            @include mq(md) {
                font-size: 16px;
            }
        }

        &__title {
            color: $blue;
            font-size: 24px;
            margin: 0;
            margin-bottom: 20px;
            text-transform: uppercase; 
            position: relative;
            z-index: 3;

            @include mq(md) {
                font-size: 36px;
            }
        }

        &__wrapper-title {
            color: $midGray - #333;
            text-transform: uppercase;
            font-size: 14px;
            margin: 0;
            margin-bottom: 5px;

            @include mq(md) {
                font-size: 14px;
            }
        }

        &__group {
            display: flex;
        }

        &__weight {
            margin-right: 10px;
            padding-right: 10px;
            font-size: 22px;
            font-weight: 700;
            color: $midGray - #333;

            &:first-child {
                border-right: 1px solid $midGray - #333;
            }
        }

        &__back {
          position: absolute;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          border: 2px solid $white;
          top: 30%;
          left:-80px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            content: '';
            width: 15px;
            height: 15px;
            border-left: 2px solid $white;
            border-top: 2px solid $white;
            transform: rotate(-45deg) translateX(10%);
          }
        }

        &__benefit {
          position: absolute;
          bottom: 10%;
          right: 0;
          transform: translateX(180%);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 200px;
          text-align: center;

          h4{
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 10px;
            color: $lightGray;
          }

          p{ 
            font-size: 14px;
            margin: 0;
            color: $lightGray;
          }

          img {
            width: 70px;
            height: auto;
            display: block;
            margin: 0 0 10px;
          }
        }
    }

    &__interstitial {
        padding: 40px 0;
        background: $lightestGray;

        &__text {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            color: $darkestGray;
            width: 90%;
            
            @include mq(md) {
                font-size: 20px;
                width: 55%;
            }

            span {
                font-weight: 700;
            }
        }
    }

    &__beneficios {
        padding: 40px 0;

        &__title {
            text-align: center;
            color: $blue;
            font-size: 40px;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 40px;
        }

        &__wrapper {
            @include mq(md) {
                display: flex;
            }
        }

        &__description {
            display: flex;
            justify-content: center;
            flex-direction: column;
            z-index: 2;
            position: relative;
            padding-top: 220px;
            
            @include mq(md) {
                width: 25%;
                padding-top: 0;
            }
        }

        &__description-wrapper {
            @include trans;

            border-radius: 10px;
            opacity: 0;
            width: 100%;
            padding: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);

            &.-active {
                opacity: 1;
            }

            @include mq(md) {
              padding: 60px 30px;
            }

        }

        &__description-icon {
          float: left;
          width: 40px;
          height: 210px;
          display: flex;
          align-items: center;
          margin-right: 15px;

          @include mq(md) {
            width: 100%;
            height: auto;
            display: block;
            margin-right: 0;
          }

            img {
                width: 100%;
                height: auto;
                display: block;
                margin-left: auto;
                margin-right: auto;

                @include mq(md) {
                  width: 25%;
                }
            }
        }

        &__description-title {
            color: $darkGray;
            font-weight: 700;
        }

        &__description-text {
            color: $midGray;
            margin-bottom: 0;
        }

        &__image {
            
            height: 0;
            overflow: hidden;
            position: relative;
            padding-bottom: 100%;
            width: 100%;
            margin: 40px 0;
            transform: translateX(-30px);

            @include mq(md) {
                margin: 0;
            }

            @include mq(md) {
                padding-bottom: 50%;
                width: 50%;
            }

            a {
                @include trans;

                position: absolute;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                border: 4px solid $darkGray;
                cursor: pointer;

                &:hover {
                    border: 4px solid $red + #333;
                }

                &:nth-child(1) {
                    top: 35%;
                    left: 30%;
                }

                &:nth-child(2) {
                    top: 35%;
                    left: 56%;
                }

                &:nth-child(3) {
                    top: 45%;
                    left: 42%;
                }

                &:nth-child(4) {
                    top: 48%;
                    left: 70%;
                }

                &:nth-child(5) {
                    top: 60%;
                    left: 88%;
                }

                &:nth-child(6) {
                    top: 65%;
                    left: 55%;
                }

                &.-active {
                    border: 4px solid $red;
                }

            }

            &--gatos {
                a{
                    &:nth-child(1) {
                        top: 25%;
                        left: 36%;
                    }
    
                    &:nth-child(2) {
                        top: 37%;
                        left: 60%;
                    }
    
                    &:nth-child(3) {
                        top: 45%;
                        left: 32%;
                    }
    
                    &:nth-child(4) {
                        top: 48%;
                        left: 50%;
                    }
    
                    &:nth-child(5) {
                        top: 83%;
                        left: 40%;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 40px;
            align-items: center;
            
            @include mq(md) {
                padding-left: 30px;
                width: 30%;
                margin-bottom: 0;
                align-items: flex-start;
            }
        }

        &__list-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;
            width: 50%;
            text-decoration: none;

            @include mq(md) {
                width: auto;
            }

            &.-active {
                #{$p}__beneficios {
                    &__list-icon {
                        border: 3px solid $lightYellow;
                    }
                    &__list-text {
                        color: $lightYellow;
                    }
                }
            }

            &:hover {
                #{$p}__beneficios {
                    &__list-icon {
                        border: 3px solid $lightYellow;
                    }
                    &__list-text {
                        color: $lightYellow;
                    }
                }
            }

            &--gatos {
                &.-active {
                    #{$p}__beneficios {
                        &__list-icon--gatos {
                            border: 3px solid $red;
                        }
                        &__list-text--gatos {
                            color: $red;
                        }
                    }
                }
    
                &:hover {
                    #{$p}__beneficios {
                        &__list-icon--gatos {
                            border: 3px solid $red;
                        }
                        &__list-text--gatos {
                            color: $red;
                        }
                    }
                }
    
            }
        }

        &__list-icon {
            @include trans;

            width: 50px;
            height: 48.97px;
            border-radius: 50%;
            border: 2px solid $white;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 50.4%; 
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 45px;
                height: auto;
            }
        }

        &__list-text {
            @include trans;

            margin: 0;
            margin-left: 20px;
            color: $midGray;
            width: 50%;
            font-weight: 500;
            font-size: 14px;
        }

        &__highlights {
            width: 100%;
            border-top: 1px solid $red;
            border-bottom: 1px solid $red;
            padding: 40px 0 20px;

            @include mq(md) {
                padding: 40px;
            }
        }

        &__highlights-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            margin-bottom: 20px;

            @include mq(md) {
                margin-bottom: 0;
            }
        }

        &__highlights-icon {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: $red;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            
            i {
                color: $white;
                font-size: 28px;
            }
        }

        &__highlights-text {
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            color: $midGray;
            width: 75%;
        }
    }

    &__descripcion {
        padding-top: 0;
        padding-bottom: 40px;

        @include mq(md) {
            padding: 40px 0;
        }

        &__container {
            margin-bottom: 20px;

            @include mq(md) {
                margin-bottom: 0;
            }
        }
        &__title {
            color: $darkestGray;
            text-transform: uppercase;
            font-style: 40px;
            margin: 0;
            padding-bottom: 10px;
            margin-bottom: 20px;
            width: 100%;
            border-bottom: 2px solid $blue;
        }

        &__text {
            color: $midGray;
            margin: 0;
            font-size: 12px;

            @include mq(md) {
                font-size: 14px;
            }

            &--italic {
                font-style: italic;
            }
        }

        &__table {
            overflow-x: auto;

            @include mq(md) {
                overflow-x: visible;
            }
        }
    }

    &__consejos {
        z-index: 2000;

        @include mq(md) {
            position: relative;
            display: flex;
            flex-wrap: wrap;
        }
        
        &__container {
            width: 100%;

            @include mq(md) {
                width: 33.33%;
            }
        }
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    &__title {
        color: $darkestGray;
        text-transform: uppercase;
        font-weight: 700;
        padding: 10px 0;
    }
    
    &__header {
        font-weight: 700;
        font-size: 14px;
        border: 2px solid $blue;
        padding: 10px 10px 10px 0;

        &--alt {
            padding: 10px 0 10px 10px;
        }

        span {
            margin-top: 5px;
            font-weight: 300;
            font-size: 12px;
            margin-bottom: 0;
            font-style: italic;
        }
    }

    th {
        white-space: nowrap;
    }

    &__row {
        &:not(:last-child) {
            border-bottom: 1px solid $midGray;
        }
    }

    &__text {
        font-size: 14px;
        text-align: center;
        border-top: 2px solid $blue;
        border-left: 2px solid $blue;
        border-right: 2px solid $blue;
        border-bottom: 2px solid $blue;
        padding: 5px;

        &--top {
            border-top: 2px solid transparent;

            &--right {
                border-right: 2px solid transparent;
            }

            &--left {
                border-left: 2px solid transparent;
            }
        }

        &--bottom {
            border-bottom: 2px solid transparent;

            &--right {
                border-right: 2px solid transparent;
            }

            &--left {
                border-left: 2px solid transparent;
            }
        }
    }

    &__description {
        padding: 10px 0;
        font-size: 14px;

        &--dark {
            font-weight: 700;
            color: $darkestGray;
        }
    }
}