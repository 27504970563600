.header {

    $h: &;
    @include trans;
    
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;

    &.-contacto {
        background: $white;

        .header__item {
            a {
                @include mq(md) {
                    color: $darkestGray;
                }
            }
            .-dark {
                opacity: 1;
            }
    
            .-light {
                opacity: 0;
            }
        }
    
        .header__logo {
            .-dark {
                opacity: 1;
            }
    
            .-light {
                opacity: 0;
            }
        }
    
        .header__network {
            i {
                color: $blue;
    
                @include mq(md) {
                    color: $darkestGray;

                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    
        .header {
            &.-open {
                .header__network {
                    i {
                        color: $white;
                    }
                }
    
                .header__logo {
                    .-dark {
                        opacity: 0;
                    }
                
                    .-light {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.-js-hovered {
        background: $white;

        #{$h}__logo {
            .-dark {
                opacity: 1;
            }

            .-light {
                opacity: 0;
            }
        }

        #{$h}__item {
            a {
                @include mq(md) {
                    color: $darkestGray;
                }

                &:hover,
                &:active,
                &:focus {
                    @include mq(md) {
                        color: $blue;
                    }
                }

            }
        }

        #{$h}__network {
            i {
                @include mq(md) {
                    color: $darkestGray;
                }
            }
        }
    }

    &.-js-scrolled {
        background: $white;

        #{$h}__container,
        #{$h}__nav,
        #{$h}__list {
            @include mq(md) {
                height: 70px;
            }
        }

        #{$h}__logo {
            .-dark {
                opacity: 1;
            }

            .-light {
                opacity: 0;
            }
        }

        #{$h}__item {
            &:after {
                @include mq(md) {
                    bottom: 20px;
                }
            }
        
            a {
                @include mq(md) {
                    line-height: 70px;
                    color: $darkestGray;
                }

                &:hover,
                &:active,
                &:focus {
                    @include mq(md) {
                        color: $blue;
                    }
                }
            }
        }

        #{$h}__subitem {
            a{
                @include mq(md) {
                    line-height: 50px;
                }
            }
        }

        #{$h}__network {
            i {
                color: $darkestGray;
            }
        }
    }

    &__container {
        @include trans;
        display: flex;
        align-items: center;
        padding: 20px 0;
        justify-content: space-between;
        
        @include  mq(md) {
            height: 100px;
            padding: 0;
        }
    }

    &__logo {
        @include trans;
        z-index: 2000;
        height: 80px;
        position: relative;
        
        .-dark {
            opacity: 0;
        }
        
        @include mq(md) {
            min-width: 250px;
            height: 100%;
        }

        img {
            @include trans;

            position: absolute;
            left: 0;
            top: 10px;
            height: 90%;
            width: auto;
        }
    }

    &__nav {
        @include trans;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow-y: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background: $blue;
        transform: translateX(-100%);        

        @include mq(md) {
            height: 100%;
            position: static;
            transform: translateX(0);
            background: transparent;
            overflow: visible;
        }

    }

    &__list {
        margin-top: 130px;
        width: 100%;

        @include mq(md) {
            margin-top: 0;
            // width: auto;
            padding-top: 0;
            display: flex;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }

    &__item {
        @include trans;

        position: relative;
        z-index: 2;
        text-transform: uppercase;
        font-weight: 700;
        
        @include mq(md) {
            height: 100%;
            padding: 0 10px;
        }

        a {
            height: 50px;
            display: flex;
            align-items: center;
            color: $white;
            text-decoration: none;
            display: block;
            line-height: 50px;
            padding: 0 15px;
            
            @include mq(md) {
                line-height: 100px;
                font-size: 12px;
                height: 100%;
                // line-height: auto;
                padding: 0;
            }
        }

        &:after {
            @include trans;

            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background: $red;
            top: 49px;

            @include mq(md) {
                bottom: 30px;
                top: auto;
            }
        }

        &:hover {
            height: 100%;

            a {
                @include mq(md) {
                    color: $blue;
                }
            }

            &:after {
                width: 100%;

                @include mq(md) {
                    width: calc(100% - 20px);
                }
            }
    
            #{$h}__sublist {
                pointer-events: all;
                height: 100%;
                overflow: visible;
                border-bottom: 2px solid $red;
                
                @include mq(md) {
                    z-index: 1;
                    opacity: 1;
                    transform: translate3d(-50%, 0, 0);
                    border-bottom: none;
                    background: $white;
                }
            }

            #{$h}__subitem {
                height: 50px;
                padding-left: 30px;

                @include mq(md) {
                    height: 50px;                    
                    background: $white;
                }
                
                a {
                    color: $white;

                    @include mq(md) {
                        color: $darkestGray;
                    }
                }
            }
        }
    }

    &__link-mobile {
        position: absolute;
        height: 50px;
        top: 0;
        right: 0;
        width: 50px;
        z-index: 1000;

        @include mq(md) {
            display: none !important;
        }
    }

    &__sublist {
        @include trans;
        height: 0;
        pointer-events: none;
        overflow: hidden;
        
        
        @include mq(md) {
            z-index: -1;
            overflow: visible;
            height: auto;
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 100%;
            background: $white;
            transform: translate3d(-50%, 0, 0);
        }
    }

    &__subitem {
        @include trans;

        position: relative;
        text-transform: capitalize;
        font-weight: 700;
        height: 0;
        overflow: hidden;

        @include mq(md) {
            border-top: 1px solid $lightGray;
            border-right: 1px solid $lightGray;
            border-left: 1px solid $lightGray;
            padding: 0 60px 0 20px;
            min-width: 220px;
            height: 50px;
            padding-left: 0;
        }
        
        a {
            display: block;
            text-decoration: none;
            color: $blue;

            @include mq(md) {
                color: $darkestGray;
                height: 50px;
                line-height: 50px;
            }
        }

        &--submenu {
            &:after {
                content: '';
                @include trans;

                position: absolute;
                top: 20px;
                right: 25px;
                width: 10px;
                height: 10px;
                border-right: 1px solid $white;
                border-bottom: 1px solid $white;
                transform: rotate(-45deg);

                @include mq(md) {
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg);
                    border-right: 1px solid $black;
                    border-bottom: 1px solid $black;
                }
            }
        }

        &:last-child {

            @include mq(md) {
                border-bottom: 1px solid $lightGray;
            }
        }

        &:hover {
            height: 100% !important;
            overflow: visible;

            @include mq(md) {
                height: 50px !important;
            }

            a {
                @include mq(md) {
                    color: $blue;
                }
            }

            &:after {
                @include mq(md) {
                    border-right: 1px solid $blue;
                    border-bottom: 1px solid $blue;
                }
            }

            #{$h}__subsublist {
                pointer-events: all;
                height: 100%;
                overflow: visible;
                opacity: 1;

                @include mq(md) {
                    z-index: 1;
                    top: -1px;
                    height: auto;
                }
            }

            #{$h}__subsubitem {
                pointer-events: all;
                height: 50px;
                padding-left: 30px;
                overflow: visible;
                opacity: 1;
                z-index: 1;

                @include mq(md) {
                    top: 1px;
                }
            }
        }
    }

    &__subsublist {
        @include trans;

        height: 0;
        overflow: hidden;
        pointer-events: none;
        opacity: 0;
        
        @include mq(md) {
            position: absolute;
            left: 100%;
            top: 0;
            background: $white;
        }
    }

    &__subsubitem {
        @include trans;

        white-space: nowrap;
        color: $white;
        height: 0;
        overflow: hidden;
        opacity: 0;

        @include mq(md) {
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            border-top: 1px solid $lightGray;
            border-left: 1px solid $lightGray;
            border-right: 1px solid $lightGray;
            color: $darkestGray;
            background: $white;

        }

        &:hover {
            @include mq(md) {
                color: $blue;
            }
        }
    }

    &__social-wrapper {
        display: flex;
        align-items: center;
    }

    &__social {
        display: flex;
        z-index: 2000;
    }

    &__network {
        margin-left: 20px;

        i {
            @include trans;
            color: $white;
            font-size: 24px;

            &:hover {
                color: $blue;
            }
        }
    }
    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        z-index: 2000;
        margin-left: 20px;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $blue;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        &:after {
            content: '';
            position: absolute;
            top: 0; 
            left: 0;
            right: 0;
            height: 120px;
            background: $blue;
            z-index: 1000;
            border-bottom: 1px solid darken($blue, 10%)
        }

        #{$h}__hamburguer {
            span {
                background: $white;
            }
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__logo {
            .-dark {
                opacity: 0;
            }

            .-light {
                opacity: 1;
            }
        }

        #{$h}__nav {
            transform: translateX(0);

        }

        #{$h}__network {
            i {
                color: $white;
            }
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
