.contacto {
    // height: 100vh;
    background: $white;
    padding-top: 120px;

    &__image {
        height: 50vh;
        width: 100%;
        position: relative;
        overflow: hidden;
        
        @include mq(md) {
            height: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: auto;
            width: 100%;

            @include mq(md) {
                height: 100%;
                width: auto;
            }
        }
    }

    &__form {
        padding: 40px 0;
        
        @include mq(md) {
            padding: 80px 40px 0 80px;
        }

        &__title {
            color: $blue;
            font-size: 40px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &__text {
            color: $darkestGray;
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 20px;
        }

        &__social {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 60px;
        }

        &__social-text {
            margin: 0;
            color: $mainColor;
            font-size: 16px;
            font-weight: 500;
            margin-right: 20px;
        }

        &__social-list {
            display: flex;

            li {
                margin-right: 20px;
            }
            
            i {
                font-size: 24px;
                color: $darkestGray;

                &:hover {
                    color: $blue;
                }
            }
        }

        &__group {
            width: 100%;
            margin-bottom: 15px;
        }

        &__input {
            width: 100%;
            height: 50px;
            border: none;
            background: $lightGray;
            padding-left: 20px;
            color: $darkestGray;
            outline: none;

            option {
                color: $darkestGray;
            }

            &--textarea {
                height: 200px;
                padding: 20px;
                resize: none;
            }
        }

        &__button {
            color: $white;
            background: $red;
            height: 50px;
            padding-left: 20px;
            padding-right: 20px;
            border: none;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            position: relative;
            width: 100%;
            text-align: left;
            
            @include mq(md) {
                display: block;
                margin-left: auto;
                width: 30%;
            }

            &:after {
                position: absolute;
                content: '';
                height: 0;
                width: 0;
                border-left: 6px solid $white;
                border-right: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-top: 6px solid transparent;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                
            }
        }
    }

    &__info-row {
      padding-top: 50px;
    }

    &__info {
        padding-top: 40px;

        a{
            cursor: pointer;
            text-decoration: none;
            color: $darkestGray;
        }

        &__title {
            color: $blue;
            font-size: 30px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &__text {
            display: block;
            color: $darkestGray;
            margin: 0;
            margin-bottom: 20px;
            font-weight: 500;
        }
    }
}