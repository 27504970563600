.gatos {
    overflow: hidden;
    $g: &;

    &__hero {
        height: 50vh;
        width: 100vw;
        overflow: hidden;
        position: relative;
        background: url('/images/gatos/hero.jpg') no-repeat center center / cover;
        
        @include mq(md) {
            padding-bottom: 56.5%;
            height: 0;
        }

        iframe {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%,  0);
            width: 110%;
            height: 110%;
            pointer-events: none;

            @include mq(md) {
                display: block;
            }
        }

        &__arrow-down {
          border: 2px solid $white;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          text-decoration: none;
          position: absolute;
          bottom: 20%;
          right: 20%;

            i {
                color: $white;
            }
        }
    }

    &__productos {
      background: $white;
      position: relative;

        &__options {
            display: flex;
            flex-wrap: wrap;
            
            @include mq(md) {
                flex-wrap: nowrap;
                text-align: center;
                width: 100%;
            }
        }

        &__button,
        &__subbutton {
            line-height: 50px;
            margin: 0;
            text-transform: uppercase;
            color: $white;
            font-weight: 700;
            font-size: 14px;
            border: none;
            outline: none;
            background: $darkestGray;
            height: 50px;
            padding: 0;
            width: 100%;
            cursor: pointer;
        }

        &__option {
            @include trans;

            width: 50%;
            position: relative;

            @include mq(md) {
                flex-shrink: 0;
                flex-grow: 1;
                width: auto;
                z-index: 2000;
            }

            &:hover {
                .gatos__productos__button {
                    background: $blue;
                }

                .gatos__productos__subbutton {
                    opacity: 1;
                }

                .gatos__productos__sublist {
                    opacity: 1;
                    pointer-events: all;
                    z-index: 2;
                    
                    @include mq(md) {
                        z-index: -1;
                    }
                }
            }

            &.-active {
                .gatos__productos__button {
                    background: $blue;
                }
            }
        }

        &__sublist {
            @include trans;

            z-index: 2;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            background: $white;
            top: 150px;
            width: 100vw;
            left: 0;

            @include mq(md) {
                top: 49px;
                transform: translateX(-50%);
                width: 100%;
                left: 50%;
                z-index: -1;
            }

            &.-active {
                opacity: 1;
                pointer-events: all;
                z-index: 2;

                @include mq(md) {
                    z-index: -1;
                }
            }

            &--xs-positioning {
                left: -100%;
                
                @include mq(md) {
                    left: 50%;
                }
            }

            &--xs-top-positioning {
                top: 100px;

                @include mq(md) {
                    top: 50px;
                }
            }
        }

        &__subbutton {
            @include trans;

            color: $darkestGray;
            height: 50px;
            opacity: 1;
            background: $white;

            &:hover {
                color: $blue;
            }
        }

        &__slider {
            position: relative;
            width: 100%;
            height: 130vh;
            overflow: hidden;

            @include mq(md) {
                height: 50vw;
            }

            &__slider-controls {
                display: none;

                @include mq(md) {
                    display: block;
                }
            }

            .arrow-left,
            .arrow-right {
                position: absolute;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                height: 50px;
                width: 50px;
                border: 1px solid $midGray;
                border-radius: 50%;
                display: none;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                @include mq(md) {
                    display: flex
                }

                i {
                    color: $midGray;
                }
            }

            .arrow-left {
                left: 2%;
            }

            .arrow-right {
                right: 2%;
            }
        }

        &__slide {
            @include trans;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            z-index: -1;
            height: 100%;

            @include mq(md) {
                display: flex;
                flex-wrap: nowrap;
            }

            &.-active {
                opacity: 1;
                z-index: 1;
            }
        }

        &__slide-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            min-height: 80vh;
            width: 100%;
            
            @include mq(md) {
                justify-content: center;
                width: 50%;
                min-height: auto;
                height: 100%;
            }
            
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%,  0);
            }
        }

        &__slide-image {
            position: absolute;
            height: 100%;
            left: 50%;
            top: -4%;
            transform: translateX(-50%);
            z-index: 2;
            overflow: hidden;
            width: 60%;

            @include mq(md) {
                display: block;
                width: 25%;
                top: 0;
            }

            img {
                position: absolute;
                height: auto;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%,  0);
            }
        }

        &__slide-content {
            margin: 20px;

            @include mq(md) {
                margin-left: 20%;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }

        &__slide-subtitle {
            margin: 0;
            margin-bottom: 5px;
            font-size: 16px;
            color: $darkestGray;
        }

        &__slide-title {
            margin: 0;
            margin-bottom: 10px;
            color: $blue;
            text-transform: uppercase;
            font-size: 24px;
            line-height: 1.1;
            
            @include mq(md) {
                font-size: 36px;
            }

            span {
              font-size: 0.7em;
            }
        }

        &__slide-text {
            margin: 0;
            margin-bottom: 20px;
            color: $darkestGray;
        }

        &__slide-button {
            height: 50px;
            line-height: 50px;
            padding-left: 20px;
            padding-right: 60px;
            border: none;
            text-transform: uppercase;
            background: $red;
            color: $white;
            font-size: 14px;
            font-weight: 700;
            position: relative;
            cursor: pointer;
            display: block;
            text-decoration: none;
            width: 100%;

            @include mq(md) {
                width: 60%;
            }

            &:after {
                position: absolute;
                content: '';
                height: 0;
                width: 0;
                border-left: 6px solid $white;
                border-right: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-top: 6px solid transparent;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
            }
        }

        &__container-image {
            height: 0;
            width: 100%;
            padding-bottom: 100%;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%,  0);
                width: 100%;
                height: auto;
            }
        }
    }

    &__consejos {
      background: $white;
      position: relative;

        @include mq(md) {
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }

        &__container {
            width: 100%;

            @include mq(md) {
                width: 33.33%;
            }
        }
    }

    &__location {
      background: $white;
      position: relative;
        
        @include mq(md) {
            display: flex;
            flex-wrap: nowrap;
        }
        
        &__container {
            width: 100%;
            padding: 0 15px;

            @include mq(md) {
                width: 33.33%;
                padding: 40px;
            }

            &--double {
                height: 0;
                position: relative;
                padding-bottom: 100%;
                margin-bottom: 20px;
                
                @include mq(md) {
                    width: 66.66%;
                    padding-bottom: 56.5%;
                    margin-bottom: 0;
                }

                iframe {
                    @include trans;
                    
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    opacity: 0;
                    
                    &.-active {
                        opacity: 1;
                    }
                }
            }
        }

        &__title {
            margin: 0;
            margin-bottom: 20px;
            color: $blue;
            text-transform: uppercase;
        }

        &__input-wrapper {
            position: relative;
            width: 100%;
            height: 50px;
            display: flex;
            margin-bottom: 20px;

            i {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__input {
            height: 50px;
            border: none;
            background: $lightGray;
            width: 100%;
            padding-left: 40px;
            font-size: 14px;
            border-radius: 3px;

            &::placeholder {
                font-weight: 700;
                color: $darkestGray;
            }
        }

        &__text {
            color: $midGray;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 14px;
            padding-bottom: 20px;
            position: relative;
            margin-bottom: 20px;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -60px;
                width: 150%;
                height: 1px;
                background: $lightGray;
            }
        }

        &__address {
            @include trans;
            display: block;
            text-decoration: none;
            padding: 20px 0;
            cursor: pointer;

            &:hover,
            &.-active {
                background: $blue;
                padding-left: 20px;

                #{$g}__location {
                    &__address-title,
                    &__address-text,
                    &__subtitle {
                        color: $white;

                        a {
                          color: $white;
                        }
                    }
                }
            }
        }

        &__address-title {
            @include trans;

            margin: 0;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-size: 16px;
        }

        &__address-text {
            @include trans;

            margin: 0;
            margin-bottom: 5px;
            text-decoration: none;
            outline: none;
            color: $darkestGray;
            font-size: 14px;
            font-weight: 500;
            display: block;

            i {
              margin-right: 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__subtitle {
            @include trans;

            margin: 10px 0;
            text-transform: uppercase;
            color: $midGray;
            font-size: 14px;
        }
    }
}