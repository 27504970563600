.articulo {
    &__hero {
        height: 60vh;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
            height: auto;
        }
    }

    &__content {
        padding: 40px 0;

        h3 {
            color: $darkestGray;
            text-transform: uppercase;
            font-size: 12px;
            margin: 0;
            margin-bottom: 20px;

            @include mq(md) {
                font-size: 14px;
            }
        }

        h2 {
            color: $blue;
            font-size: 22px;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 20px;

            @include mq(md) {
                font-size: 40px;
            }
        }

        p,
        li {
            color: $darkestGray;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            margin: 0;
            margin-bottom: 20px;

            @include mq(md) {
                font-size: 18px;
            }
        }

        ul {
            list-style: disc;
            padding-left: 20px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        margin-top: 40px;
        
        &__text {
            color: $darkestGray;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            margin: 0;
            margin-right: 20px;
        }

        &__list {
            display: flex;
            list-style: none !important;

            li {
                margin-right: 20px;
                margin-bottom: 0;
            }
    
            i {
                font-size: 24px;
                color: $darkestGray;

                &:hover {
                    color: $blue;
                }
            }
        }
    }
    &__consejos {
        @include mq(md) {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin-top: 80px;
        }
        &__container {
            width: 100%;
            margin-bottom: 1px;

            @include mq(md) {
                width: 33.33%;
                margin-bottom: 0;
            }
        }
    }
}