.quienes-somos {
    overflow: hidden;

    &__hero {
        background: url('/images/quienes-somos/hero.jpg') no-repeat center center / cover;
        height: 50vh;
        width: 100vw;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: flex-end;

        @include mq(md) {
            padding: 40px;
            height: 110vh;
            transform: translateY(-100px);
        }

        iframe {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%,  0);
            width: 110%;
            height: 110%;
            pointer-events: none;

            @include mq(md) {
                display: block;
            }
        }

        &:after {
            position: absolute;
            content: '';
            top:0;
            left:0;
            right:0;
            bottom: 0;
            background: rgba(0,0,0,.5);

            @include mq(md) {
                display: none;
            }
        }

        &__title {
            color: $white;
            text-transform: uppercase;
            font-weight: 300;
            line-height: 1.3;
            font-size: 20px;
            z-index: 2;
            position: relative;
            
            @include mq(md) {
                width: 60%;
                font-size: 30px;
            }
        }
    }

    &__interstitial {
        background: $lightestGray;

        @include mq(md) {
            display: flex;
            flex-wrap: nowrap;
        }

        &__container {
            width: 100%;
            position: relative;
            overflow: hidden;
            padding-bottom: 35%;
            height: 0;

            @include mq(md) {

            }
        }

        &__content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 90%;
            font-weight: 500;
            color: $blue;
            margin: 0;
            
            @include mq(md) {
                font-size: 30px;
            }
        }

        &__images {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
        }

        &__image {
            width: 100%;
            position: relative;
            margin-left: auto;
            margin-right: auto;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 100%;
                height: auto;
            }
        }
    }

    &__investigacion,
    &__calidad {
      margin-bottom: 40px;

        @include mq(md) {
            height: 50vw;
            display: flex;
            margin-bottom: 0;
        }

        &__container {
            position: relative;
            overflow: hidden;
            //height: 50vh;
            
            @include mq(md) {
                width: 50%;
                height: 100%;
            }

            .container {
                //height: 50vh;

                @include mq(md) {
                    height: auto;
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
    
                    @include customMq(1200px) {
                        width: 570px;
                    }
    
                    @include customMq(1400px) {
                        width: 630px;
                    }
                }
            }

            &--image{
              height: 50vh;

              @include mq(md) {
                height: 100%;
              }
            }
        }

        .row,
        .col-24 {
            //height: 50vh;

            @include mq(md) {
                height: auto;
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            
            @include mq(md) {
                display: block;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &__title {
            color: $blue;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 20px;
            
            @include mq(md) {
                font-size: 40px;
                margin-bottom: 40px;
            }
        }

        &__text {
            color: $darkestGray;
            margin: 0;
            font-weight: 300;
            margin-bottom: 20px;
            
            @include mq(md) {
                font-size: 20px;
            }

            span {
                font-weight: 700;
            }

            &--bold {
              font-weight: 500;
            }
        }

        &__image {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 100%;
                height: auto;
            
                @include mq(md) {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    &__calidad {
        display: flex;
        flex-direction: column;

        @include mq(md) {
            flex-direction: row;
        }

        .container {
            left: 0;
            right: auto;
        }

        &__container {
            &:first-child {
                order: 2;

                @include mq(md) {
                    order: 1
                }
            }   

            &:last-child {
                order: 1;

                @include mq(md) {
                    order: 2
                }
            }   
        }
    }
}