.consejos {
    &__hero {
        height: 100vh;
    
        &__slider {
            position: relative;
            height: 100%;
            overflow: hidden;
        }

        .arrow-left,
        .arrow-right {
            position: absolute;
            z-index: 2;
            height: 50px;
            width: 50px;
            border: 1px solid $midGray;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 2;
            display: flex;
            bottom: 35px;
            
            @include mq(md) {
                top: 50%;
                transform: translateY(-50%);
                bottom: auto;
            }

            i {
                color: $midGray;
            }
        }

        .arrow-left {
            left: 2%;
        }

        .arrow-right {
            right: 2%;
        }

        &__slider-dots {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            z-index: 1000;
            
            span {
                border: 2px solid $red;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px;

                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: $red;
                    border-radius: 50%;                   
                    opacity: 0;
                }

                &.-active {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &__slide {
            @include trans;

            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: flex-end;
            padding-bottom: 100px;
            background: $black;
            opacity: 0;
            pointer-events: none;

            img {
                position: absolute;
                height: 50vh;
                opacity: .7;
                width: auto;
                top: 0;
                left: 50%;
                transform: translate3d(-50%, 0, 0);                

                @include mq(md) {
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;
                }
            }

            &.-active {
                opacity: 1;
                pointer-events: all;
            }
        }

        .container {
            position: absolute;
            top: 50vh;
            height: 50vh;
            padding: 0;

            @include mq(md) {
                padding: 0 15px;
                height: auto;
                position: static;
                top: auto;
            }
        }

        &__content {
            padding: 40px 15px 0;
            height: 50vh;
            position: absolute;
            top: 0;
            background: $lightestGray;
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @include mq(md) {
                padding: 0 40px;
                display: block;
                background: transparent;
                width: auto;
                height: auto;
                display: block;
                position: static;
            }
        }

        &__subtitle {
            margin: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
            color: $darkestGray;
            font-size: 14px;

            @include mq(md) {
                color: $white;
                font-size: 16px;
            }
        }

        &__title {
            font-size: 24px;
            margin: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
            color: $darkestGray;
                        
            @include mq(md) {
                color: $white;
                font-size: 40px;
            }
        }

        &__button {
            display: block;
            height: 50px;
            line-height: 50px;
            border: none;
            border-radius: 3px;
            background: $red;
            text-transform: uppercase;
            color: $white;
            cursor: pointer;
            width: 100%;
            text-align: center;
            text-decoration: none;

            @include mq(md) {
                width: 200px;
            }
        }
    }

    &__list {
        padding-top: 40px;

        &__title {
            color: $blue;
            text-align: center;
            margin: 0;
            margin-bottom: 40px;
            text-transform: uppercase;
            font-size: 30px;
            
            @include mq(md) {
                font-size: 40px;
            }
        }
    }

    &__filters {
        display: flex;
        margin-bottom: 40px;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mq(md) {
            justify-content: center;
            flex-wrap: nowrap;

        }

        &__filter-group {
            width: 44%;
            height: 50px;
            background: $lightGray;
            border-radius: 3px;
            display: flex;
            margin-bottom: 15px;

            @include mq(md) {
                margin: 0 5px;
                width: 200px;
            }

            &:nth-child(odd) {
                margin-left: 15px;

                @include mq(md) {
                    margin-left: 5px;
                }
            }

            &:nth-child(even) {
                margin-right: 15px;

                @include mq(md) {
                    margin-right: 5px;
                }
            }
        }

        &__filter {
            position: absolute;
            left: -9999px;

            &:checked + label {
                background: $red;
                color: $white;
            }
        }

        &__label {
            @include trans;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $darkestGray;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            border-radius: 3px;
            cursor: pointer;
        }

        &__select {
            width: 100%;
            background: $lightGray;
            border: none;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            color: $darkestGray;
            padding-left: 20px;
            outline: none;
        }
    }

    &__news {
        @include mq(md) {
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }

        &__container {
            width: 100%;

            @include mq(md) {
                width: 33.33%;
            }


            &.-hidden {
                display: none;
            }
        }

    }
}

@keyframes wrapperAnimation {
    0%{
        bottom: 40px;
    }

    100%{
        bottom: auto;
        top: 40px;
    }
}