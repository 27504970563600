.consejo {
    @include trans;

    height: 250px;
    overflow: hidden;
    position: relative;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    background: $black;
    justify-content: space-between;

    @include mq(md) {
        justify-content: flex-end;
        width: 100%;
    }

    img {
        @include trans;
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        opacity: .7;
        width: 100%;
        height: auto;
    }

    &:hover {
        @include mq(md) {
            background: $lightGray;
            justify-content: space-between;
        }

        .consejo__wrapper {
            @include mq(md) {
                top: 40px;
                bottom: auto;
            }
        }

        .consejo__subtitle {
            @include mq(md) {
                color: $darkestGray;
            }
        }

        .consejo__title {
            @include mq(md) {
                color: $blue;
            }
        }

        .consejo__button {
            @include mq(md) {
                opacity: 1;
                bottom: 40px;
            }
        }

        img {
            @include mq(md) {
                opacity: 0;
            }
        }
    }

    &__wrapper {
        @include trans;

        padding: 0 40px;
        top: auto;
        z-index: 2;
        
        @include mq(md) {
            position: absolute;
            width: 100%;
            bottom: 40px;

        }
    }

    &__subtitle {
        color: $white;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 10px;
    }

    &__title {
        text-transform: uppercase;
        font-size: 24px;
        margin: 0;
        color: $white;
    }

    &__button {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 40px;
        z-index: 2;
        position: relative;
        width: 50%;
        color: $white;
        
        @include mq(md) {
            color: $darkestGray;
            display: block;
            opacity: 0;
            position: absolute;
            bottom: -100%;            
            left: 40px;
            padding-left: 0;
            width: auto;
        }

        &:after {
            position: absolute;
            content: '';
            height: 0;
            width: 0;
            border-left: 6px solid $darkestGray;
            border-right: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;

            @include mq(md) {
                right: -40px;
            }
        }
    }
}